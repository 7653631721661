import { doesWindowExist } from '../general-helper/general-helper';

/*
 * Storybook Helper Functions For Components
 * When components need to take Storybook into account. These functions should be used as sparingly as possible.
 * In an ideal world, components should never need to know that they are being rendered in Storybook
 */

/*
 * Used to detect when a component is being rendered in Storybook.
 * can be used to ignore useInView in when in Storybook to get clean snapshots
 * NOTE: this function only works client-side and should not be relied on for any SSR rendering
 */
export function isStorybook() {
	return doesWindowExist() && typeof process !== 'undefined' && Boolean(process.env.STORYBOOK);
}
