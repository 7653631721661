export interface RouteConfig {
	// #region Maps to RouteProps from react-router
	path: string | string[];
	exact?: boolean;
	strict?: boolean;
	sensitive?: boolean;
	// #endregion
	// Versioned Router setting to disable version changed app updating for this route.
	disableAppUpdate?: boolean;
	// Specify a loading component to show when loading this route.
	// TODO: we can support this using fallback in loadable-components, but would require a jsx transform to test our route transformer
	// loading?: JSX.Element;
	// Allow custom RouteProps
	[key: string]: any;
}
/**
 * A HOC that configures the react router with route configuration. This must be used with the typescript configure-route-transformer
 * which will rip the HOC out and only use it for configuration.
 */
/**
 * @param config The configuration data. It mostly mirrors RouteProps, but also supports VersionedRouter
 * config and specifying a custom loading component.
 * @param component The component being wrapped.
 */
export function configureRoute<P extends object>(config: RouteConfig, Component: React.ComponentType<P>): React.ComponentType<P> {
	return Component;
}
