// extracted by mini-css-extract-plugin
export var circle = "UHPq0";
export var grid = "oBYtF";
export var gridColumn1 = "M8ZDb";
export var gridColumn2 = "vCRpC";
export var gridColumn3 = "XUTOQ";
export var gridCustom5 = "CUXC6";
export var gridCustomItem = "CYL12";
export var tealLine = "cMgU4";
export var widgetGap = "_b6Fn";
export var widgetHeight = "mCIR0";