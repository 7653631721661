import React, { FunctionComponent, PropsWithChildren } from 'react';
import { AnalyticsEvent } from '../../../helpers/analytics/analytics.helper';
import { StyledLink } from '../../common-components/link/styled-link.component';
import { ArrowRightAltIcon } from '../../svg/icons.component';

export type HomeDashboardWidgetHeaderProps = {
	heading: string;
	description?: string;
	url?: string;
	linkText?: string;
	analyticsEvent?: AnalyticsEvent;
};

const HomeDashboardWidgetHeader: FunctionComponent<HomeDashboardWidgetHeaderProps> = ({
	url,
	linkText,
	heading,
	analyticsEvent,
	description
}) => (
	<div className="flex flex-column pa3">
		<div className="flex items-center justify-between">
			<p className="b f4 mv0">{heading}</p>
			{url && linkText && (
				<StyledLink url={url} className="f5" analyticsEvent={analyticsEvent}>
					<span>{linkText} </span>
					<ArrowRightAltIcon className="v-mid" />
				</StyledLink>
			)}
		</div>
		{description && <p className="f5 mt2 mb0">{description}</p>}
	</div>
);

export type HomeDashboardWidgetWrapperProps = {
	testId?: string;
} & HomeDashboardWidgetHeaderProps;

export const HomeDashboardWidgetWrapper: FunctionComponent<PropsWithChildren<HomeDashboardWidgetWrapperProps>> = ({
	children,
	heading,
	url,
	linkText,
	analyticsEvent,
	description,
	testId
}) => (
	<div
		style={{ minWidth: '20rem' }}
		className={`flex flex-column flex-auto bg-theme-white br2 w-100 flexBasisHalf w-40-ns`}
		data-testid={testId}>
		<HomeDashboardWidgetHeader
			heading={heading}
			description={description}
			url={url}
			linkText={linkText}
			analyticsEvent={analyticsEvent}
		/>
		{children}
	</div>
);
