import gql from 'graphql-tag';
import { TrackingLinkFields } from '../common-fields.queries';

const HomePageImageFields = gql`
	fragment HomePageImageFields on Image {
		id
		imageType
		description
		lowQualityPlaceholder
	}
`;
const SplashFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment SplashFields on HomePageSplash {
		id
		background {
			...HomePageImageFields
		}
		backgroundTracking
		callToAction {
			mobile {
				...HomePageImageFields
			}
			desktop {
				...HomePageImageFields
			}
			alignment
			style
			link {
				...TrackingLinkFields
			}
			tracking
			text
		}
	}
`;

const CarouselItemFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment CarouselItemFields on HomePageCarouselItem {
		title
		image {
			...HomePageImageFields
		}
		trackingLink {
			...TrackingLinkFields
		}
	}
`;

const CarouselFields = gql`
	${CarouselItemFields}
	fragment CarouselFields on HomePageCarousel {
		id
		title
		items {
			...CarouselItemFields
		}
	}
`;

const SaleCategoriesFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment SaleCategoriesFields on HomePageSaleCategories {
		id
		items {
			title
			image {
				...HomePageImageFields
			}
			trackingLink {
				...TrackingLinkFields
			}
			subtitle
		}
	}
`;
export const ExclusiveDealsFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment ExclusiveDealsFields on HomePageExclusiveDeals {
		id
		items {
			trackingLink {
				...TrackingLinkFields
			}
			image {
				...HomePageImageFields
			}
			title
			artworkImage {
				...HomePageImageFields
			}
			textStyle
			subtitle
		}
	}
`;

const InspirationItemFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment InspirationItemFields on InspirationArticle {
		image {
			...HomePageImageFields
		}
		title
		subtitle
		trackingLink: link {
			...TrackingLinkFields
		}
	}
`;

const ShopByLookFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment ShopByLookFields on HomePageShopByLook {
		id
		slides {
			title
			description
			image {
				...HomePageImageFields
			}
			trackingLink {
				...TrackingLinkFields
			}
		}
	}
`;

const TipsInspirationFields = gql`
	${TrackingLinkFields}
	${InspirationItemFields}
	fragment TipsInspirationFields on HomePageInspiration {
		__typename
		id
		title
		description
		callToAction {
			...TrackingLinkFields
		}
		content {
			...InspirationItemFields
		}
	}
`;

const BannerFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment BannerFields on HomePageBanner {
		id
		desktop {
			...HomePageImageFields
		}
		mobile {
			...HomePageImageFields
		}
		link {
			...TrackingLinkFields
		}
	}
`;

const FeaturedBrandsFields = gql`
	${HomePageImageFields}
	${TrackingLinkFields}
	fragment FeaturedBrandsFields on HomePageFeaturedBrands {
		id
		items {
			name
			image {
				...HomePageImageFields
			}
			link {
				...TrackingLinkFields
			}
		}
	}
`;

export const HOMEPAGE = gql`
	${HomePageImageFields}
	${SplashFields}
	${SaleCategoriesFields}
	${CarouselFields}
	${TipsInspirationFields}
	${ExclusiveDealsFields}
	${ShopByLookFields}
	${BannerFields}
	${FeaturedBrandsFields}
	query HomePage($input: HomePageInput) {
		homepage(input: $input) {
			splash {
				...SplashFields
			}
			saleCategories {
				...SaleCategoriesFields
			}
			sections {
				... on DynamicYieldTemplate {
					__typename
					id
					name
				}
				... on HomePageProject {
					id
					image {
						...HomePageImageFields
					}
					title
				}
				... on HomePageCarousel {
					...CarouselFields
				}
				... on HomePageInspiration {
					...TipsInspirationFields
				}
				... on HomePageExclusiveDeals {
					...ExclusiveDealsFields
				}
				... on HomePageShopByLook {
					...ShopByLookFields
				}
				... on HomePageBanner {
					...BannerFields
				}
				... on HomePageFeaturedBrands {
					...FeaturedBrandsFields
				}
			}
		}
	}
`;
