import React, { FunctionComponent, useState } from 'react';
import { generatePath } from 'react-router';
import { ShippingProgressStep } from '../../../../__generated__/graphql-client-types';
import { ACCOUNT_ORDERS_LINK, ORDER_LINK } from '../../../../constants/links';
import { buildGTMViewAllOrders, buildGTMOrderNumberClick } from '../../../../helpers/analytics/gtm/event-builders';
import { ClickableElement } from '../../../buttons';
import { Link } from '../../../common-components/link/link.component';
import { ShipmentProgressBar } from '../../../order-tracking/shipment-progress-bar/shipment-progress-bar.component';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../svg/icons.component';
import { HomeDashboardWidgetWrapper } from '../../home-dashboard-widget-wrapper/home-dashboard-widget-wrapper.component';

export type ShipmentTrackingData = {
	shipmentTrackingLabel: string;
	shipmentTrackingDate: string;
	shippingProgress: ShippingProgressStep;
};

export type DashboardOrderTrackingProps = {
	orderNumber: number;
	orderDate: string;
	shipmentTrackingData: ShipmentTrackingData[];
};

export const DashboardOrderTracking: FunctionComponent<DashboardOrderTrackingProps> = (orderTrackingData) => {
	const [currentShipmentIndex, setCurrentShipmentIndex] = useState<number>(0);
	const { orderNumber, orderDate, shipmentTrackingData } = orderTrackingData;
	const shipmentGroup = shipmentTrackingData[currentShipmentIndex] || {};

	const hoverClass = 'hover-theme-primary';
	const disabledClass = 'not-allowed b--theme-grey-light theme-grey-light';
	const totalNumOfShipments = shipmentTrackingData.length;
	const backDisabled = currentShipmentIndex === 0;
	const forwardDisabled = currentShipmentIndex >= totalNumOfShipments - 1;

	const shipmentBack = () => {
		if (backDisabled) {
			return;
		}
		setCurrentShipmentIndex(currentShipmentIndex - 1);
	};

	const shipmentForward = () => {
		if (forwardDisabled) {
			return;
		}
		setCurrentShipmentIndex(currentShipmentIndex + 1);
	};
	return (
		<HomeDashboardWidgetWrapper
			url={ACCOUNT_ORDERS_LINK}
			heading="Your Latest Order"
			linkText="View All Orders"
			analyticsEvent={{ data: buildGTMViewAllOrders() }}
			testId="order-tracking-widget">
			<div className="flex justify-center mh3 mb3 mt2 mt0-ns">
				<ShipmentProgressBar currentStep={shipmentGroup.shippingProgress} isHomepageDashboardVersion={true} />
			</div>
			<div className="flex flex-column items-center mb3 mb0-ns">
				<div className="f7 theme-grey">{shipmentGroup.shipmentTrackingLabel.toUpperCase() || 'ESTIMATED SHIP DATE'}</div>
				<div className="f3 b lh-copy theme-grey-darker" style={{ letterSpacing: '0.05rem' }}>
					{shipmentGroup.shipmentTrackingDate || 'Pending'}
				</div>
			</div>
			<div className="flex justify-between pa3 b--theme-grey-light bt">
				<div className="flex flex-column items-start">
					<div className="f7">ORDER DATE</div>
					<div className="f5 mt1" style={{ letterSpacing: '0.05rem' }}>
						{orderDate}
					</div>
				</div>
				{totalNumOfShipments > 1 && (
					<div className="flex flex-column items-center">
						<div className="f7">SHIPMENT</div>
						<div className="flex items-center f6 mt1">
							<ClickableElement
								ariaLabel="shipment back"
								className={`flex items-center mr3 ${backDisabled ? disabledClass : hoverClass}`}
								onClick={shipmentBack}
								automationHook="previous-shipment"
								testId="shipment-back">
								<ChevronLeftIcon className={`${backDisabled ? disabledClass : ''}`} />
							</ClickableElement>
							<div>
								{currentShipmentIndex + 1} of {totalNumOfShipments}
							</div>
							<ClickableElement
								ariaLabel="shipment forward"
								className={`flex items-center ml3 ${forwardDisabled ? disabledClass : hoverClass}`}
								onClick={shipmentForward}
								automationHook="next-shipment"
								testId="shipment-forward">
								<ChevronRightIcon className={`${forwardDisabled ? disabledClass : ''}`} />
							</ClickableElement>
						</div>
					</div>
				)}
				<div className="flex flex-column items-end">
					<div className="f7 mb1">ORDER NUMBER</div>
					<Link
						url={generatePath(ORDER_LINK, { orderNumber })}
						className="f5 theme-primary"
						analyticsEvent={{ data: buildGTMOrderNumberClick() }}>
						{orderNumber}
					</Link>
				</div>
			</div>
		</HomeDashboardWidgetWrapper>
	);
};
