import { kebabCase } from '@fergdigitalcommerce/fergy-utilities';
import loadable, { LoadableComponent } from '@loadable/component';
import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';
import { isStorybook } from '../../../helpers/storybook/storybook-component.helper';
import { DynamicYieldTemplate, HomeSection } from '../../../types/home.types';
import { FeaturedBrandsProps } from '../featured-brands/featured-brands.component';
import { HomeBannerProps } from '../home-banner/home-banner.component';
import { HomeCarousel, HomeCarouselProps } from '../home-carousel/home-carousel.component';
import {
	isCarouselSection,
	isDynamicYieldSection,
	isTipsInspirationSection,
	isExclusiveDealsSection,
	isProjectsSection,
	isShopByLookSection,
	isBannerSection,
	isFeaturedBrands
} from '../home-common.helpers';
import { HomeDynamicYield } from '../home-dynamic-yield/home-dynamic-yield.component';
import {
	HomeExclusiveDealsCardOption,
	HomeExclusiveDealsCardOptionProps
} from '../home-exclusive-deals-card-option/home-exclusive-deals-card-option.component';
import { HomeTipsInspirationProps } from '../home-tips-inspiration/home-tips-inspiration.component';
import { HomepageProjectsProps } from '../projects/projects.component';
import { HomeShopByLookProps } from '../shop-by-look/shop-by-look/shop-by-look.component';

const LoadableHomeTipsInspiration = loadable(
	() => import(/* webpackChunkName: "home-tips-inspiration" */ '../home-tips-inspiration/home-tips-inspiration.component'),
	{
		resolveComponent: ({ HomeTipsInspiration }) => HomeTipsInspiration
	}
);
const LoadableHomeProjects = loadable(() => import(/* webpackChunkName: "home-projects" */ '../projects/projects.component'), {
	resolveComponent: ({ Projects }) => Projects
});
const LoadableNewHomeShopByLook = loadable(
	() => import(/* webpackChunkName: "new-home-shop-by-look" */ '../shop-by-look/shop-by-look/shop-by-look.component'),
	{
		resolveComponent: ({ HomeShopByLook }) => HomeShopByLook
	}
);
const LoadableHomeBanner = loadable(() => import(/* webpackChunkName: "home-banner" */ '../home-banner/home-banner.component'), {
	resolveComponent: ({ HomeBanner }) => HomeBanner
});
const LoadableFeaturedBrands = loadable(
	() => import(/* webpackChunkName: "home-feature-brands" */ '../featured-brands/featured-brands.component'),
	{
		resolveComponent: ({ FeaturedBrands }) => FeaturedBrands
	}
);
export type LoadableComponentProps =
	| HomeCarouselProps
	| DynamicYieldTemplate
	| HomeTipsInspirationProps
	| Omit<HomepageProjectsProps, 'onProjectCreated'>
	| HomeShopByLookProps
	| HomeBannerProps
	| FeaturedBrandsProps;

export type GenerateInViewHomeSectionProps<T = HomeExclusiveDealsCardOptionProps> = {
	Component: LoadableComponent<LoadableComponentProps> | FunctionComponent<HomeSection | HomeCarouselProps>;
	CardComponent?: FunctionComponent<T>;
} & HomeSection;

const GenerateInViewHomeSection: FunctionComponent<GenerateInViewHomeSectionProps & { automationHook?: string }> = ({
	Component,
	automationHook,
	...props
}) => {
	const { ref, inView } = useInView({ triggerOnce: true });
	/* do NOT use intesectionObserver in the Storybook environment, this was causing snap issues for the homepage story */
	const shouldRender = inView || isStorybook();
	return <div ref={ref}>{shouldRender && <Component {...props} automationHook={automationHook} />}</div>;
};

export const HomeSectionFactory: FunctionComponent<HomeSection> = (props) => {
	if (isCarouselSection(props)) {
		// using the carousel title to identify the carousel
		const carouselTitle = kebabCase(props.title);
		return <GenerateInViewHomeSection Component={HomeCarousel} {...props} automationHook={`${carouselTitle}-swipe`} />;
	} else if (isDynamicYieldSection(props)) {
		return <GenerateInViewHomeSection Component={HomeDynamicYield} {...props} automationHook={props.name} />;
	} else if (isTipsInspirationSection(props)) {
		return <GenerateInViewHomeSection Component={LoadableHomeTipsInspiration} {...props} />;
	} else if (isExclusiveDealsSection(props)) {
		return <GenerateInViewHomeSection Component={HomeCarousel} CardComponent={HomeExclusiveDealsCardOption} {...props} />;
	} else if (isProjectsSection(props)) {
		return <GenerateInViewHomeSection Component={LoadableHomeProjects} {...props} automationHook="project-tool-room-list" />;
	} else if (isShopByLookSection(props)) {
		return <GenerateInViewHomeSection Component={LoadableNewHomeShopByLook} {...props} />;
	} else if (isBannerSection(props)) {
		return <GenerateInViewHomeSection Component={LoadableHomeBanner} {...props} />;
	} else if (isFeaturedBrands(props)) {
		return <GenerateInViewHomeSection Component={LoadableFeaturedBrands} {...props} />;
	} else {
		return null;
	}
};
