import gql from 'graphql-tag';

export const CurrentInCartItemsFields = gql`
	fragment CurrentInCartItemsFields on CurrentInCartItemsResult {
		products {
			id
			manufacturer
			title
			price
			image {
				id
				description
				imageType
			}
			productUrl
		}
	}
`;

export const HOMEPAGE_DASHBOARD = gql`
	${CurrentInCartItemsFields}
	query mostRecentOrderSummary {
		mostRecentOrderSummary {
			orderNumber
			orderDate
			shipmentTrackingData {
				shipmentTrackingLabel
				shipmentTrackingDate
				shippingProgress
			}
		}
		currentInCartItemsSummary {
			...CurrentInCartItemsFields
		}
	}
`;
