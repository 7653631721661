import { CloudinaryOptions } from '../../helpers/cloudinary-helper/cloudinary-helper';

export const SALE_CATEGORIES_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	width: 280,
	height: 104,
	crop: 'fit'
};

export const CAROUSEL_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	width: 271,
	height: 290,
	crop: 'mfit'
};

export const EXCLUSIVE_DEALS_IMAGE_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	crop: 'fit',
	height: 2145,
	width: 3648
};

export const EXCLUSIVE_DEALS_IMAGE_SMALL_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	crop: 'fill'
};

export const EXCLUSIVE_DEALS_ARTWORK_SINGLE_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	crop: 'fit',
	width: 1280
};

export const EXCLUSIVE_DEALS_ARTWORK_SINGLE_SMALL_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	crop: 'fill',
	gravity: 'west',
	width: 406,
	height: 83
};

export const EXCLUSIVE_DEALS_ARTWORK_DOUBLE_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	crop: 'fill',
	gravity: 'west',
	height: 96,
	width: 472
};

export const EXCLUSIVE_DEALS_ARTWORK_MULTIPLE_CLOUDINARY_OPTIONS: CloudinaryOptions = {
	crop: 'fill',
	gravity: 'west',
	height: 78,
	width: 384
};

export const EXCLUSIVE_DEALS_TEXT_STYLES = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	WHITE: 'white',
	BLACK: 'black',
	DARK_GREY: 'grey-darker'
};

export const CARD_WIDTHS = [1280, 472, 384];

// For the current experience using Swiper, swiper handles the breakpoints for us.
// this is not the case for our carousel, we need to explicitly set these.
export const HOME_CAROUSEL_LARGE_OPTIONS: CloudinaryOptions = { width: 271, crop: 'fit' };
export const HOME_CAROUSEL_MEDIUM_OPTIONS: CloudinaryOptions = { width: 220, crop: 'fit' };
export const HOME_CAROUSEL_SMALL_OPTIONS: CloudinaryOptions = { width: 153, crop: 'fit' };

// the default minWidth we need for the top level home carousels per device, if a value isn't passed
export const DEFAULT_HOME_CAROUSEL_LARGE_CARD_MIN_WIDTH = 17;
export const DEFAULT_HOME_CAROUSEL_MEDIUM_CARD_MIN_WIDTH = 13.75;
export const DEFAULT_HOME_CAROUSEL_SMALL_CARD_MIN_WIDTH = 9.5;

// default for exclusive deals
export const EXCLUSIVE_DEALS_CAROUSEL_LARGE_CARD_MIN_WIDTH = 20;
export const EXCLUSIVE_DEALS_CAROUSEL_MEDIUM_CARD_MIN_WIDTH = 15;
export const EXCLUSIVE_DEALS_CAROUSEL_SMALL_CARD_MIN_WIDTH = 14;
